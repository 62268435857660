// External Imports
import { useContext, useState } from "react";

// Internal Imports
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import { requestAdminAPI } from "../../api/posh-api";
import Heading from "../UI/Heading";
import LoadingSpinner from "../UI/LoadingSpinner";
import uploadFile from "../../util/upload-file";
import truncate from "../../util/truncate";

/** ========================================================================
 * * Add Expense Page
 * Renders a page, where the user can add a new expense.
 * ========================================================================== */

export default function AddExpense() {
  // Load Context
  const { vehicles, fetchExpenses } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [uploading, setUploading] = useState(false);

  /* ======================= UPLOAD EXPENSE ======================= */
  const uploadExpenseHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setWarning(false);
      // Create Paload for request
      const payload = {
        vehicleId: e.target.vehicle.value,
        amount: e.target.amount.value,
        date: e.target.date.value,
        description: e.target.description.value,
        type: e.target.type.value,
      };

      // Validate Required Fields
      if (payload.vehicleId === "") throw new Error("Please select a vehicle.");

      // Add Optional Fields
      if (e.target.bookingId.value) {
        payload.bookingId = e.target.bookingId.value;
      }
      if (e.target.attachment.files[0]) {
        const title = `${payload.vehicleId}${payload.date}${truncate(
          payload.description,
          10
        )}`;
        const url = await uploadFile(
          `expenses/${title}`,
          e.target.attachment.files[0],
          setUploading
        );
        payload.attachment = url;
      }

      // Send Request
      await requestAdminAPI("add-expense", payload);
      // Refresh Expenses and Navigate Back
      fetchExpenses(() => navigate("/expenses"));
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <Heading title="Add Expense"></Heading>
      <form
        onSubmit={uploadExpenseHandler}
        className="grid items-center grid-cols-4 gap-6 my-12 text-stone-400"
      >
        <div className="grid md:col-span-2">
          <label className="text-sm">VEHICLE:</label>
          <select className="cursor-pointer form-input" name="vehicle" required>
            <option value="">Select Vehicle</option>
            {vehicles.map((vehicle) => (
              <option value={vehicle.id} key={vehicle.id}>
                {vehicle.make +
                  " " +
                  vehicle.model +
                  " " +
                  vehicle.color +
                  " " +
                  vehicle.license}
              </option>
            ))}
          </select>
        </div>
        <div className="grid">
          <label className="text-sm">AMOUNT:</label>
          <input
            className="form-input"
            name="amount"
            step="0.01"
            type="number"
            required
          />
        </div>
        <div className="grid">
          <label className="text-sm">DATE:</label>
          <input
            className="form-input"
            name="date"
            type="date"
            required
            placeholder="YYYY-MM-DD"
          />
        </div>
        <div className="grid md:col-span-4">
          <label className="text-sm">DESCRIPTION:</label>
          <input className="form-input" name="description" />
        </div>
        <div className="grid md:col-span-2">
          <label className="text-sm">ATTACHMENT:</label>
          <input type="file" className="form-input" name="attachment" />
        </div>
        <div className="grid">
          <label className="text-sm">TYPE:</label>
          <select className="form-input" name="type">
            <option value="wash">Wash</option>
            <option value="fuel">Fuel</option>
            <option value="maintenance">Maintenance</option>
            <option value="insurance">Insurance</option>
            <option value="tickets">Tickets</option>
            <option value="tolls">Tolls</option>
          </select>
        </div>
        <div className="grid">
          <label className="text-sm">BOOKING ID:</label>
          <input
            className="form-input"
            placeholder="Can be left blank"
            name="bookingId"
          />
        </div>

        {loading ? (
          <LoadingSpinner />
        ) : (
          <button type="submit" className="btn-primary">
            Submit
          </button>
        )}
        {warning && <p className="text-red-500">{warning}</p>}
        {uploading && (
          <p className="text-stone-400">Uploading... {uploading}%</p>
        )}
      </form>
    </div>
  );
}
