// External Packages
import { useContext } from "react";
import { AiFillFacebook, AiFillInstagram, AiOutlineGoogle } from "react-icons/ai";
import { MdLocationOn, MdPhone } from "react-icons/md";

// Internal Packages
import { DataContext } from "../../context/data-context";

export default function Footer() {
  
  return (
    <div className="grid py-12 mx-4 mt-4 mb-2 shadow-lg md:grid-cols-2 rounded-xl bg-gradient-to-t from-yellow-600 to-yellow-500 px-14">
      <div>
        <p className="text-4xl text-white font-Play">POSH</p>
        <div className="grid gap-1 my-2">
            <div  className="grid text-sm">
              <div className="flex items-center space-x-2">
                <MdLocationOn className="text-white" />
                <p>8500 North Stemmons Fwy, Dallas, TX 75201</p>
              </div>
              <div className="flex items-center space-x-2">
                <MdPhone className="text-white" />
                <p>+18887811356</p>
              </div>
            </div>
        </div>
        
        <p className="text-sm text-stone-200">
          Copyright © 2022 All Rights Reserved
        </p>
      </div>
      <div className="grid items-end justify-end">
        <div className="text-xs text-right text-stone-200">
          <p>Mode: {process.env.REACT_APP_ENVIRONMENT}</p>
          <p>Server: {process.env.REACT_APP_ADMIN_API_URL}</p>
          <p>Project: {process.env.REACT_APP_PROJECT_ID}</p>
        </div>
        <div className="flex items-end justify-end space-x-4">
          <a href="https://www.instagram.com/poshcars.io/" target="_blank">
            <AiFillInstagram className="text-2xl text-white" />
          </a>
          <a href="https://www.facebook.com/p/POSH-Cars-61551725499679/" target="_blank">
          <AiFillFacebook className="text-2xl text-white" />
          </a>
          <a href="https://maps.app.goo.gl/KvpibgQLq4hCGY5o8" target="_blank">
          <AiOutlineGoogle className="text-2xl text-white" />
          </a>
        </div>
      </div>
    </div>
  );
}
