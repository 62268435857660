import { arrayToCsv } from "./arrayToCsv";
import { timestampToString } from "./timestamp-to-date";

/* ======================= DOWNLOAD CSV ======================= */
export const downloadBookingsCSV = (bookings, vehicles) => {
  if (!bookings) return;
  const formatted = bookings.map((booking) => {
    const formattedBooking = { ...booking };

    const vehicle = vehicles.find(
      (vehicle) => vehicle.id === formattedBooking.vehicle?.id
    );

    formattedBooking.vin = vehicle?.vin;
    formattedBooking.date_created = timestampToString(
      formattedBooking.date_created,
      "MM/dd/yyyy hh:mm"
    );
    formattedBooking.start_date = timestampToString(
      formattedBooking.start_date,
      "MM/dd/yyyy hh:mm"
    );
    formattedBooking.end_date = timestampToString(
      formattedBooking.end_date,
      "MM/dd/yyyy hh:mm"
    );
    formattedBooking.vehicleId = formattedBooking.vehicle?.id;
    formattedBooking.vehicle = `${formattedBooking.vehicle.make} ${formattedBooking.vehicle.model} ${formattedBooking.vehicle.year}`;
    formattedBooking.price = formattedBooking.price?.total;
    formattedBooking.customerId = formattedBooking.customer?.id;
    formattedBooking.customer = `${formattedBooking.customer.firstName} ${formattedBooking.customer.lastName}`;

    formattedBooking.payment_id = `${formattedBooking.payment?.id}`;
    formattedBooking.payment_status = `${formattedBooking.payment?.status}`;

    delete formattedBooking.payment;
    delete formattedBooking.extension;

    return formattedBooking;
  });
  arrayToCsv(formatted, "bookings");
};
