// External Packages
import {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  Fragment,
} from "react";
import { useSearchParams } from "react-router-dom";

// Internal Packages
import { AuthContext } from "../../context/auth-context";
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import { uploadImageToFirebase } from "../../util/upload-image-to-firebase";

// External Components
import { FaEdit } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import { MdRefresh } from "react-icons/md";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { FaChartBar } from "react-icons/fa";

// Internal Components
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import Heading from "../UI/Heading";
import DetailPair from "../UI/DetailPair";
import Switch from "../UI/Switch";
import VehicleStatus from "./VehicleStatus";
import SelectPair from "../UI/SelectPair";
import VehicleControls from "./VehicleControls";
import { requestAdminAPI } from "../../api/posh-api";
import VehiclePartner from "./VehiclePartner";
import BookingStatus from "./BookingStatus";
import { ACCEPTABLE_IMAGE_FORMATS } from "../../util/constants";
import ConfirmationModal from "../UI/ConfirmationModal";

/** ========================================================================
  ** Vehicle Information Page
  This page displays all information about a vehicle.
 * ========================================================================== */

export default function VehicleInfo() {
  // Get User From Context
  const { user } = useContext(AuthContext);
  const { fetchVehicles, brands, activeLocations } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Get Vehicle ID from URL
  const [searchParms] = useSearchParams();
  const id = searchParms.get("id");

  // Initialize States
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [warning, setWarning] = useState(true);
  const [vehicle, setVehicle] = useState(null);
  const [cornerImage, setCornerImage] = useState();
  const [frontImage, setFrontImage] = useState();
  const [backImage, setBackImage] = useState();
  const [sideImage, setSideImage] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Initialize Refs
  const frontRef = useRef();
  const backRef = useRef();
  const sideRef = useRef();
  const cornerRef = useRef();

  // =================== FETCH VEHICLE DATA ===================
  const fetchVehicleHandler = useCallback(async () => {
    try {
      setLoading(true);
      const response = await requestAdminAPI("fetch-vehicle", { id: id });
      setVehicle(response);
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      setLoading(false);
    }
  }, [id]);

  // =================== EDIT VEHICLE ===================
  const editVehicleHandler = async (event) => {
    try {
      event.preventDefault();
      // Clear any previous warnings
      setWarning();
      setLoading(true);
      // Get Vehicle ID
      const vehicleId = vehicle.id;

      // Upload Images: Hold URls
      let imageUrls = {};

      // Upload only if new image is provided
      if (frontImage)
        imageUrls.front = await uploadImageToFirebase(
          `images/${vehicleId}/front`,
          "front",
          event.target.front.files[0],
          () => {}
        );
      if (backImage)
        imageUrls.back = await uploadImageToFirebase(
          `images/${vehicleId}/back`,
          "back",
          event.target.back.files[0],
          () => {}
        );
      if (sideImage)
        imageUrls.side = await uploadImageToFirebase(
          `images/${vehicleId}/side`,
          "side",
          event.target.side.files[0],
          () => {}
        );
      if (cornerImage)
        imageUrls.corner = await uploadImageToFirebase(
          `images/${vehicleId}/corner`,
          "corner",
          event.target.corner.files[0],
          () => {}
        );

      // Create payload for all vehicle information
      const payload = {
        id: vehicleId,
        vin: vehicle.vin,
        make: event.target["Make"].value || vehicle.make,
        model: event.target["Model"].value || vehicle.model,
        year: event.target["Year"].value || vehicle.year,
        trim: event.target["Trim"].value || vehicle.trim,
        drive: event.target["Drive"].value || vehicle.drive,
        fuel: event.target["Fuel Type"].value || vehicle.fuel,
        seats: event.target["Seats"].value || vehicle.seats,
        doors: event.target["Doors"].value || vehicle.doors,
        license: event.target["License Plate"].value || vehicle.license,
        license_state:
          event.target["License State"].value || vehicle.license_state,
        location: event.target["Location"].value || vehicle.location,
        color: event.target["Color"].value || vehicle.color,
        plan: {
          id:
            event.target["Plan"].value === "LUXURY"
              ? "0"
              : event.target["Plan"].value === "PLUSH"
              ? "1"
              : "2",
          name: event.target["Plan"].value,
        },
        rates: {
          daily: event.target["Daily Rate"].value || vehicle.rates.daily,
          weekly: event.target["Weekly Rate"].value || vehicle.rates.weekly,
          monthly: event.target["Monthly Rate"].value || vehicle.rates.monthly,
        },
        status: {
          available: event.target["Available"].checked,
        },
        images: {
          front: imageUrls.front || vehicle.images.front || "",
          back: imageUrls.back || vehicle.images.back || "",
          side: imageUrls.side || vehicle.images.side || "",
          corner: imageUrls.corner || vehicle.images.corner || "",
        },
      };

      // Send Payload to API
      await requestAdminAPI("edit-vehicle", payload);
      // Fetch Vehicles and Reset Edit Mode
      fetchVehicles(() => {
        setEdit(false);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // =================== DELETE VEHICLE ===================
  const deleteVehicleHandler = async () => {
    try {
      setLoading(true);
      await requestAdminAPI("delete-vehicle", { id: vehicle.id });
      fetchVehicles(() => navigate("/vehicles"));
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // ==================== Confirm Delete Vehicle
  const handleDeleteConfirmation = () => {
    setShowConfirmation(false); // Close the confirmation modal
    deleteVehicleHandler(); // Proceed with the delete operation
  };

  // =================== CHOOSE IMAGE HANDLER ===================
  const chooseImageHandler = (input) => {
    if (!edit) return;
    input.current.click();
  };

  // =================== LOCATION CHANGE HANDLER ===================
  const handleLocationChange = (newLocation) => {
    setVehicle((prev) => ({
      ...prev,
      location: newLocation,
    }));
  };

  // =================== CANCEL CHOOSE IMAGE HANDLER ===================
  const cancelChooseImageHandler = () => {
    setEdit(false);
    setCornerImage();
    setFrontImage();
    setBackImage();
    setSideImage();
  };

  // =================== ON SELECT IMAGE ===================
  const onSelectImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const file = URL.createObjectURL(e.target.files[0]);
    switch (e.target.name) {
      case "corner":
        setCornerImage(file);
        break;
      case "front":
        setFrontImage(file);
        break;
      case "back":
        setBackImage(file);
        break;
      case "side":
        setSideImage(file);
        break;
      default:
        break;
    }
  };

  // =================== Delete Vehicle Confirmation Message ===================
  const message =
    " Are you sure you want to delete  vehicle? This action is irreversible.";

  // Fetch vehicle data on load
  useEffect(() => {
    if (!vehicle) fetchVehicleHandler();
  }, [fetchVehicleHandler, vehicle]);

  if (!id)
    return (
      <div className="container">
        <p className="text-stone-400">Vehicle ID Not Provided!</p>
      </div>
    );

  if (!loading && !vehicle)
    return (
      <div className="container">
        <p className="text-stone-400">Vehicle Not Found!</p>
      </div>
    );

  return (
    <div className="container grid gap-8">
      <Heading
        title="Vehicle Details"
        text="All information below directly effects the customer portal."
      >
        {edit ? (
          <Fragment>
            <FiCheck
              onClick={() => document.getElementById("form").click()}
              className="icon"
            />
            <AiOutlineClose
              onClick={cancelChooseImageHandler}
              className="icon"
            />
          </Fragment>
        ) : (
          <Fragment>
            <FaChartBar
              onClick={() => navigate(`/vehicles/report?id=${vehicle.id}`)}
              className="icon"
            />
            <MdRefresh
              onClick={fetchVehicleHandler}
              className="text-2xl icon"
            />
            <FaEdit onClick={() => setEdit(true)} className="icon" />
          </Fragment>
        )}
      </Heading>

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <Fragment>
          {vehicle?.deleted && (
            <div className="absolute px-3 py-2 bg-red-600 rounded-md top-[21%] left-[55%] text-xs">
              Vehicle Deleted!
            </div>
          )}
          <form onSubmit={editVehicleHandler} className="grid gap-8">
            <div className="grid gap-4 text-sm md:grid-cols-4">
              <DetailPair label="Vehicle ID" value={vehicle.id} />
              <DetailPair label="VIN" value={vehicle.vin} />
              <SelectPair
                label="Make"
                options={brands.map((brand) => brand.name)}
                edit={edit}
                value={vehicle.make}
              />
              <DetailPair label="Model" value={vehicle.model} edit={edit} />
              <DetailPair label="Year" value={vehicle.year} edit={edit} />
              <DetailPair label="Trim" value={vehicle.trim} edit={edit} />
              <DetailPair label="Drive" value={vehicle.drive} edit={edit} />
              <DetailPair label="Fuel Type" value={vehicle.fuel} edit={edit} />
              <DetailPair label="Seats" value={vehicle.seats} edit={edit} />
              <DetailPair label="Doors" value={vehicle.doors} edit={edit} />
              <DetailPair
                label="License Plate"
                value={vehicle.license}
                edit={edit}
              />
              <DetailPair
                label="License State"
                value={vehicle.license_state}
                edit={edit}
              />
              <DetailPair
                label="Location"
                value={vehicle.location}
                edit={edit}
                type="select"
                options={activeLocations?.map((loc) => loc?.name)}
                onChange={handleLocationChange}
              />
              <DetailPair label="Color" value={vehicle.color} edit={edit} />
              <SelectPair
                label="Plan"
                value={vehicle.plan?.name}
                edit={edit}
                options={["LUXURY", "PLUSH", "POSH"]}
              />
              <DetailPair
                label="Daily Rate"
                value={`${vehicle.rates.daily}`}
                edit={edit}
              />
              <DetailPair
                label="Weekly Rate"
                value={`${vehicle.rates.weekly}`}
                edit={edit}
              />
              <DetailPair
                label="Monthly Rate"
                value={`${vehicle.rates.monthly}`}
                edit={edit}
              />
              <div className="grid items-center grid-cols-2">
                <Switch
                  label="Available"
                  value={vehicle.status.available}
                  edit={edit}
                />
              </div>
            </div>
            <div className="grid gap-8 text-sm md:grid-cols-4 text-stone-400">
              <div className="grid p-4 border rounded-lg cursor-pointer min-h-40 justify-items-center border-stone-400">
                <p>CORNER IMAGE:</p>
                <input
                  className="hidden"
                  type="file"
                  name="corner"
                  ref={cornerRef}
                  onChange={onSelectImage}
                  accept={ACCEPTABLE_IMAGE_FORMATS}
                />
                {cornerImage && (
                  <img
                    alt="Vehicle"
                    onClick={() => chooseImageHandler(cornerRef)}
                    className="object-fit"
                    src={cornerImage}
                  />
                )}
                {vehicle.images.corner !== "" && !cornerImage && (
                  <img
                    alt="Vehicle"
                    onClick={() => chooseImageHandler(cornerRef)}
                    className=""
                    src={vehicle.images.corner}
                  />
                )}
                {vehicle?.images?.corner === "" && !cornerImage && (
                  <AiOutlinePlus
                    className="w-10 h-10"
                    onClick={() => chooseImageHandler(frontRef)}
                  />
                )}
              </div>
              <div className="grid p-4 border rounded-lg cursor-pointer min-h-40 justify-items-center border-stone-400">
                <p>FRONT IMAGE:</p>
                <input
                  className="hidden"
                  type="file"
                  name="front"
                  ref={frontRef}
                  onChange={onSelectImage}
                  accept={ACCEPTABLE_IMAGE_FORMATS}
                />
                {frontImage && (
                  <img
                    alt="Vehicle"
                    onClick={() => chooseImageHandler(frontRef)}
                    className="object-fit"
                    src={frontImage}
                  />
                )}
                {vehicle.images.front !== "" && !frontImage && (
                  <img
                    alt="Vehicle"
                    onClick={() => chooseImageHandler(frontRef)}
                    className="object-fit"
                    src={vehicle.images.front}
                  />
                )}
                {vehicle?.images?.front === "" && !frontImage && (
                  <AiOutlinePlus
                    className="w-10 h-10"
                    onClick={() => chooseImageHandler(frontRef)}
                  />
                )}
              </div>
              <div className="grid p-4 border rounded-lg cursor-pointer min-h-40 justify-items-center border-stone-400">
                <p>BACK IMAGE:</p>
                <input
                  className="hidden"
                  type="file"
                  name="back"
                  ref={backRef}
                  onChange={onSelectImage}
                  accept={ACCEPTABLE_IMAGE_FORMATS}
                />
                {backImage && (
                  <img
                    alt="Vehicle"
                    onClick={() => chooseImageHandler(backRef)}
                    className="object-fit"
                    src={backImage}
                  />
                )}
                {vehicle.images.back !== "" && !backImage && (
                  <img
                    alt="Vehicle"
                    onClick={() => chooseImageHandler(backRef)}
                    className="object-fit"
                    src={vehicle.images.back}
                  />
                )}
                {vehicle?.images?.back === "" && !backImage && (
                  <AiOutlinePlus
                    className="w-10 h-10"
                    onClick={() => chooseImageHandler(backRef)}
                  />
                )}
              </div>
              <div className="grid p-4 border rounded-lg cursor-pointer min-h-40 justify-items-center border-stone-400">
                <p>SIDE IMAGE:</p>
                <input
                  className="hidden"
                  type="file"
                  name="side"
                  ref={sideRef}
                  onChange={onSelectImage}
                  accept={ACCEPTABLE_IMAGE_FORMATS}
                />
                {sideImage && (
                  <img
                    alt="Vehicle"
                    onClick={() => chooseImageHandler(sideRef)}
                    className="object-fit"
                    src={sideImage}
                  />
                )}
                {vehicle.images.side !== "" && !sideImage && (
                  <img
                    alt="Vehicle"
                    onClick={() => chooseImageHandler(sideRef)}
                    className="object-fit"
                    src={vehicle.images.side}
                  />
                )}
                {vehicle?.images?.side === "" && !sideImage && (
                  <AiOutlinePlus
                    className="w-10 h-10"
                    onClick={() => chooseImageHandler(sideRef)}
                  />
                )}
              </div>
            </div>
            <button type="submit" id="form" hidden />
          </form>
        </Fragment>
      )}
      <VehicleStatus vehicle={vehicle} />
      {vehicle?.bookings && <BookingStatus vehicle={vehicle} />}
      {vehicle?.partnerId && <VehiclePartner vehicle={vehicle} />}
      {vehicle?.smartcar && <VehicleControls vehicle={vehicle} />}
      <div className="info">
        {vehicle?.deleted ? (
          <Fragment></Fragment>
        ) : (
          <Fragment>
            <div
              onClick={(e) =>
                navigate(`/bookings/add?vehicleId=${vehicle.id}`, e)
              }
              className="btn-secondary"
            >
              Add Booking
            </div>
            {/* Disabling Delete Vehicle Button */}
            {/* <div
              onClick={() => setShowConfirmation(true)}
              className="btn-warning"
            >
              Delete Vehicle
            </div> */}
          </Fragment>
        )}

        {warning && <p className="text-red-600">{warning}</p>}
        <ConfirmationModal
          open={showConfirmation}
          setOpen={setShowConfirmation}
          message={message}
          onConfirm={handleDeleteConfirmation} // Function to call on confirmation
        />
      </div>
    </div>
  );
}
