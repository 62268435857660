// External Imports
import { useState, useEffect, useRef } from "react";

// Internal Imports
import LoadingSpinner from "../UI/LoadingSpinner";
import { requestAdminAPI } from "../../api/posh-api";

/** ========================================================================
 * * ChangeEndDate Component
 * Renders a form, which allows the admin to extend a booking.
 * ========================================================================== */

export default function ChangeEndDate({ booking, setExtend, onComplete }) {
  // Initialize States
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(false);
  const [customer, setCustomer] = useState();
  const [type, setType] = useState("now");

  /* 
  * Declare refs 
  Refs are used to updated values in the form fields.
  */
  const bookingFeeRef = useRef();
  const taxesRef = useRef();
  const totalFeeRef = useRef();

  /* ======================= CALCULATE TOTAL FEE ======================= */
  const calculateTotalFee = () => {
    const totalFee =
      Number(bookingFeeRef.current.value) + Number(taxesRef.current.value);
    totalFeeRef.current.value = "$" + totalFee.toString();
  };

  /* ======================= FETCH CUSTOMER ======================= */
  const fetchCustomerHandler = async () => {
    try {
      setLoading(true);
      const response = await requestAdminAPI("fetch-customer", {
        customerId: booking.customer.id,
      });
      setCustomer(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setWarning(err.message);
    }
  };

  /* ======================= EXTEND BOOKING ======================= */
  const extendBookingHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      // Ensure all required fields are filled out
      const charge = event.target.charge.value;
      const payment_method_id = event.target.payment_method_id?.value;
      const days_until_due = event.target.days_until_due?.value;

      if (charge === "payment" && !payment_method_id)
        throw new Error("Please select a payment method.");
      if (charge === "invoice" && !days_until_due)
        throw new Error("Please enter a number of days until due.");

      // Create Payload
      const payload = {
        bookingId: booking.id,
        customerId: customer.id,
        end_date: event.target.end_date.value,
        end_time: event.target.end_time.value,
        automatic_extension: event.target.automatic_extension.value === "yes",
        miles_included: event.target.miles_included.value,
        mileage_rate: event.target.mileage_rate.value,
        drop_off_location: event.target.drop_off_location.value,
        booking_fee: event.target.booking_fee.value,
        taxes: event.target.taxes.value,
        charge,
        payment_method_id,
        days_until_due,
      };

      // Send Request
      await requestAdminAPI("extend-booking", payload);
      onComplete();
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // Fetch Customer Data on load
  useEffect(() => {
    fetchCustomerHandler();
  }, []);

  if (loading)
    return (
      <div className="flex items-center justify-center h-full md:col-span-3">
        <LoadingSpinner />
      </div>
    );

  return (
    <form
      onSubmit={extendBookingHandler}
      className="grid items-end gap-4 md:grid-cols-4 md:col-span-3"
    >
      <p className="text-lg font-semibold md:col-span-4">Change End Date:</p>
      <div className="grid">
        <label className="text-sm">END DATE:</label>
        <input
          className="form-input"
          name="end_date"
          type="date"
          required
          placeholder="HH:MM"
        />
      </div>
      <div className="grid">
        <label className="text-sm">END TIME:</label>
        <input
          className="form-input"
          name="end_time"
          type="time"
          required
          placeholder="HH:MM"
        />
      </div>
      <div className="grid">
        <label className="text-sm">AUTOMATIC EXTENSION:</label>
        <select
          defaultValue={booking.automatic_extension ? "yes" : "no"}
          className="form-input"
          name="automatic_extension"
          required
        >
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>
      <div className="grid">
        <label className="text-sm">MILES INCLUDED:</label>
        <input
          type="number"
          className="form-input"
          name="miles_included"
          required
        />
      </div>
      <div className="grid">
        <label className="text-sm">EXTRA MILEAGE:</label>
        <input
          type="number"
          step="0.01"
          className="form-input"
          name="mileage_rate"
          defaultValue={booking.mileage_rate}
        />
      </div>
      <div className="grid">
        <label className="text-sm">DROP OFF LOCATION:</label>
        <input
          className="form-input"
          name="drop_off_location"
          defaultValue={booking.drop_off_location}
          type="text"
          required
        />
      </div>
      <div className="grid">
        <label className="text-sm">BOOKING FEE:</label>
        <input
          type="number"
          ref={bookingFeeRef}
          onChange={calculateTotalFee}
          className="form-input"
          name="booking_fee"
        />
      </div>

      <div className="grid">
        <label className="text-sm">TAXES:</label>
        <input
          type="number"
          step="0.01"
          ref={taxesRef}
          onChange={calculateTotalFee}
          className="form-input"
          name="taxes"
        />
      </div>
      <div className="grid">
        <label className="text-sm">TOTAL:</label>
        <input
          ref={totalFeeRef}
          className="form-input-disabled"
          name="total_fee"
          disabled
        />
      </div>
      <div className="grid">
        <label className="text-sm">CHARGE CUSTOMER:</label>
        <select
          onChange={(e) => setType(e.target.value)}
          className="form-input"
          name="charge"
          defaultValue={"now"}
        >
          <option value={"now"}>Now</option>
          <option value={"invoice"}>Send Invoice</option>
          <option value={"none"}>Do Not Charge</option>
        </select>
      </div>
      {type === "now" && (
        <div className="grid">
          <label className="text-sm">PAYMENT METHOD:</label>
          {customer?.default_payment_method ? (
            <select className="form-input" name="payment_method_id" required>
              <option value={customer.default_payment_method.id}>
                {customer.default_payment_method.brand}{" "}
                {customer.default_payment_method.last4}
              </option>
              {customer.payment_methods &&
                customer.payment_methods.map((pm, idx) => (
                  <option key={idx} value={pm.id}>
                    {pm.brand} {pm.last4}
                  </option>
                ))}
            </select>
          ) : (
            <input
              className="form-input-disabled"
              name="payment_method_id"
              placeholder="No Payment Methods Attached"
              disabled
            />
          )}
        </div>
      )}
      {type === "invoice" && (
        <div className="grid">
          <label className="text-sm">DAYS UNTIL DUE:</label>
          <input
            type="number"
            name="days_until_due"
            className="form-input"
            required
          />
        </div>
      )}
      {type === "none" && (
        <div className="grid">
          <label className="text-sm">BOOKING STATUS:</label>
          <select className="form-input" name="status">
            <option value="active">Active</option>
          </select>
        </div>
      )}
      <div className="grid items-end gap-4 md:grid-cols-2">
        <button type="submit" className="btn-primary">
          Confirm
        </button>
        <div onClick={() => setExtend(false)} className="btn-warning">
          Cancel
        </div>
      </div>
      {warning && <p className="text-red-600">{warning}</p>}
    </form>
  );
}
