import { createContext, useState, useEffect, useContext } from "react";

import {
  fetchAllBookings,
  fetchAllVehicles,
  fetchAllCustomers,
  fetchAllData,
  fetchAllPayments,
  fetchAllCharging,
  fetchAllExpenses,
  fetchAllPartners,
  fetchAllInvoices,
  fetchAllBrands,
  fetchAllCoupons,
  fetchAllLocations,
} from "../api/fetch";
import { timestampToDate } from "../util/timestamp-to-date";
import { AuthContext } from "./auth-context";

export const DataContext = createContext({
  bookings: [],
  customers: [],
  activeCustomers: [],
  partners: [],
  vehicles: [],
  activeVehicles: [],
  brands: [],
  payments: [],
  coupons: [],
  invoices: [],
  charging: [],
  expenses: [],
  locations: [],
  activeLocations: [],
  loadingData: true,
  fetchAllData: () => {},
  refreshData: () => {},
  fetchBookings: () => {},
  fetchCustomers: () => {},
  fetchPartners: () => {},
  fetchVehicles: () => {},
  fetchBrands: () => {},
  fetchPayments: () => {},
  fetchCoupons: () => {},
  fetchInvoices: () => {},
  fetchExpenses: () => {},
  fetchCharging: () => {},
  fetchLocations: () => {},
});

export const DataContextProvider = ({ children }) => {
  const { user, logout } = useContext(AuthContext);
  const [loadingData, setLoadingData] = useState(true);
  const [bookings, setBookings] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [partners, setPartners] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [brands, setBrands] = useState([]);
  const [payments, setPayments] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [charging, setCharging] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [locations, setLocations] = useState([]);
  const [activeLocations, setActiveLocations] = useState([]);
  const [activeCustomers, setActiveCustomers] = useState([]);
  const [activeVehicles, setActiveVehicles] = useState([]);

  useEffect(() => {
    if (user) fetchData();
  }, [user]);

  /** ========================================================== FETCH ALL DATA */
  const fetchData = async () => {
    setLoadingData(true);
    try {
      const data = await fetchAllData(user);
      setBookings(
        data.bookings.sort((a, b) => {
          return timestampToDate(b.start_date) - timestampToDate(a.start_date);
        })
      );
      setCustomers(
        data.customers.sort((a, b) => {
          return a.firstName > b.firstName
            ? 1
            : a.firstName < b.firstName
            ? -1
            : 0;
        })
      );
      setActiveCustomers(
        data.customers.filter(
          (customer) => !customer?.deleted && !customer?.dnr
        )
      );
      setPartners(
        data.partners.sort((a, b) => {
          return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
        })
      );
      setVehicles(
        data.vehicles.sort((a, b) => {
          return a.model > b.model ? 1 : a.model < b.model ? -1 : 0;
        })
      );
      setActiveVehicles(data.vehicles.filter((vehicle) => !vehicle?.deleted));
      setBrands(data.brands);
      setPayments(
        data.payments.sort((a, b) => {
          return (
            timestampToDate(b.date_created) - timestampToDate(a.date_created)
          );
        })
      );
      setCoupons(data.coupons);
      setInvoices(
        data.invoices.sort((a, b) => {
          return (
            timestampToDate(b.date_created) - timestampToDate(a.date_created)
          );
        })
      );
      setCharging(data.charging);
      setExpenses(data.expenses);
      setLocations(data.locations);
      setActiveLocations(data?.locations.filter((loc) => !loc?.disabled));
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      if (err.message.includes("Invalid Admin ID")) {
        logout();
        window.location.href = "https://www.poshcars.io";
      }
      setLoadingData(false);
    }
  };

  /** ========================================================== REFRESH DATA */
  const refreshData = async (callback) => {
    await fetchData();
    callback && callback();
  };

  /** ========================================================== FETCH ALL BOOKINGS */
  const fetchBookings = async () => {
    setLoadingData(true);
    try {
      const bookings = await fetchAllBookings(user);
      setBookings(
        bookings.sort((a, b) => {
          return timestampToDate(b.start_date) - timestampToDate(a.start_date);
        })
      );
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  /** ========================================================== FETCH ALL CUSTOMERS */
  const fetchCustomers = async () => {
    setLoadingData(true);
    try {
      const customers = await fetchAllCustomers(user);
      setCustomers(
        customers.sort((a, b) => {
          return a.firstName > b.firstName
            ? 1
            : a.firstName < b.firstName
            ? -1
            : 0;
        })
      );
      setActiveCustomers(
        customers.filter((customer) => !customer?.deleted && !customer?.dnr)
      );
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  /** ========================================================== FETCH ALL PARTNERS */
  const fetchPartners = async (callback) => {
    setLoadingData(true);
    try {
      const partners = await fetchAllPartners(user);
      setPartners(
        partners.sort((a, b) => {
          return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
        })
      );
      callback && callback();
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  /** ========================================================== FETCH ALL VEHICLES */
  const fetchVehicles = async (callback) => {
    setLoadingData(true);
    try {
      const vehicles = await fetchAllVehicles(user);
      setVehicles(vehicles);
      setActiveVehicles(vehicles.filter((vehicle) => !vehicle?.deleted));
      callback && callback();
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  /** ========================================================== FETCH ALL BRANDS */
  const fetchBrands = async (callback) => {
    setLoadingData(true);
    try {
      const brands = await fetchAllBrands(user);
      setBrands(brands);
      callback && callback();
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  /** ========================================================== FETCH ALL PAYMENTS */
  const fetchPayments = async (callback) => {
    setLoadingData(true);
    try {
      const payments = await fetchAllPayments(user);
      setPayments(
        payments.sort((a, b) => {
          return (
            timestampToDate(b.date_created) - timestampToDate(a.date_created)
          );
        })
      );
      callback && callback();
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  /** ========================================================== FETCH ALL COUPONS */
  const fetchCoupons = async (callback) => {
    setLoadingData(true);
    try {
      const coupons = await fetchAllCoupons(user);
      setCoupons(coupons);
      callback && callback();
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  /** ========================================================== FETCH ALL INVOICES */
  const fetchInvoices = async (callback) => {
    setLoadingData(true);
    try {
      const payments = await fetchAllInvoices(user);
      setPayments(
        payments.sort((a, b) => {
          return (
            timestampToDate(b.date_created) - timestampToDate(a.date_created)
          );
        })
      );
      callback && callback();
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  /** ========================================================== FETCH ALL CHARGING */
  const fetchCharging = async (callback) => {
    setLoadingData(true);
    try {
      const charging = await fetchAllCharging(user);
      setCharging(charging);
      callback && callback();
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  /** ========================================================== FETCH ALL Expenses */
  const fetchExpenses = async (callback) => {
    setLoadingData(true);
    try {
      const expenses = await fetchAllExpenses(user);
      setExpenses(expenses);
      callback && callback();
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  /** ========================================================== FETCH ALL LOCATIONS */
  const fetchLocations = async (callback) => {
    setLoadingData(true);
    try {
      const locations = await fetchAllLocations(user);
      setLocations(locations);
      callback && callback();
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  return (
    <DataContext.Provider
      value={{
        bookings,
        customers,
        activeCustomers,
        partners,
        vehicles,
        activeVehicles,
        brands,
        payments,
        coupons,
        invoices,
        charging,
        expenses,
        locations,
        activeLocations,
        loadingData,
        fetchData,
        refreshData,
        fetchBookings,
        fetchCustomers,
        fetchPartners,
        fetchInvoices,
        fetchVehicles,
        fetchBrands,
        fetchPayments,
        fetchCoupons,
        fetchCharging,
        fetchExpenses,
        fetchLocations,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
