/** ======================= CREATE BOOKING ======================= */
export const createBooking = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/create-booking`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= COMPLETE BOOKING ======================= */
export const completeBooking = async (user, bookingId) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/complete-booking`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bookingId: bookingId }),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= CANCEL BOOKING ======================= */
export const cancelBooking = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/cancel-booking`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= START BOOKING ======================= */
export const startBooking = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/start-booking`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= EXTEND BOOKING ======================= */
export const extendBooking = async (user, data) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/extend-booking`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ADD PAYMENT ======================= */
export const addPayment = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/add-payment`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ADD COUPON ======================= */
export const addCoupon = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/add-coupon`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= EDIT COUPON ======================= */
export const editCoupon = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/edit-coupon`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= CHARGE INVOICE ======================= */
export const chargeInvoice = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/charge-invoice`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= PAY INVOICE ======================= */
export const payInvoice = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/pay-invoice`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ADD VEHILCE ======================= */
export const addVehicle = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/add-vehicle`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= EDIT VEHILCE ======================= */
export const editVehicle = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/edit-vehicle`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= EDIT CUSTOMER ======================= */
export const editCustomer = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/edit-customer`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= DELETE VEHILCE ======================= */
export const deleteVehicle = async (user, id) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/delete-vehicle`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= DELETE EXPENSE ======================= */
export const deleteExpense = async (user, id) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/delete-expense`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ expenseId: id }),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= UPLOAD CHARGING ======================= */
export const uploadCharging = async (user, charging) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/upload-charging`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ charging }),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= SEND MESSAGE ======================= */
export const sendMessage = async (user, customerId, message) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/send-message`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ customerId, message }),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ADD EXPENSE ======================= */
export const addExpense = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/add-expense`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ADD TO DNR ======================= */
export const addToDNR = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/add-to-dnr`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= START SCREENING ======================= */
export const startScreening = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/start-screening`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= CHECK SCREENING STATUS ======================= */
export const checkScreeningStatus = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/check-screening-status`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= Add Partner Vehicle ======================= */
export const addPartnerVehicle = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/add-partner-vehicle`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= EDIT PARTNER VEHICLE ======================= */
export const editPartnerVehicle = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/edit-partner-vehicle`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= EDIT PARTNER ======================= */
export const editPartner = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/edit-partner`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= SEND INVOICE LINK ======================= */
export const sendInvoiceLink = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/send-invoice-link`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ADD BRAND ======================= */
export const addBrand = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/add-brand`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ADD PARTNER PAYMENT ======================= */
export const addPartnerPayment = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/add-partner-payment`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ADD PARTNER PAYMENT ======================= */
export const deletePartnerPayment = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/delete-partner-payment`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= CREATE STRIPE INVOICE ======================= */
export const createStripeInvoice = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/create-stripe-invoice`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= SEND QUOTE ======================= */
export const sendQuote = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/send-quote`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= SETUP ACH ======================= */
export const setupAch = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/setup-ach`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= EDIT LOCATION edit-vehicle======================= */
export const editLocation = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/edit-location`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ADD LOCATION ======================= */
export const addLocation = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/add-location`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};
