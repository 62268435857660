// External Packages
import { useState, useContext, useRef } from "react";
import InputMask from "react-input-mask";
import { AiOutlinePlus } from "react-icons/ai";

// Internal Packages
import { DataContext } from "../../context/data-context";
import { AuthContext } from "../../context/auth-context";
import { UtilityContext } from "../../context/util-context";
import { addLocation } from "../../api/add";

// Internal Components
import Heading from "../UI/Heading";
import LoadingSpinner from "../UI/LoadingSpinner";
import { ACCEPTABLE_IMAGE_FORMATS } from "../../util/constants";
import { uploadImageToFirebase } from "../../util/upload-image-to-firebase";

/** ========================================================================
 * * Add Location Page
 * Renders a page, which allows adding a new lcoations to the database.
 * ========================================================================== */

export default function AddLocation() {
  // Load Contexts
  const { user } = useContext(AuthContext);
  const { fetchLocations, refreshData } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize Processing States
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();

  const [phone, setPhone] = useState("+1 ");
  const [image, setImage] = useState();
  const imageRef = useRef();

  // ==================================== ON SELECT IMAGE
  /** A single function is used to set the selected image
   * after the file input is triggered based on
   * the name of the input. */
  const onSelectImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const file = URL.createObjectURL(e.target.files[0]);
    setImage(file);
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;

    // Prevent clearing of +1
    if (!value.startsWith("+1 ")) {
      setPhone("+1 ");
    } else {
      setPhone(value);
    }
  };

  // ==================================== CHOOSE IMAGE HANDLER
  /** This function uses a ref given to a hidden input to click on it */
  const chooseImageHandler = (input) => {
    input.current.click();
  };

  // ==================================== SUBMIT HANDLER
  const submitHandler = async (event) => {
    try {
      event.preventDefault();
      // Clear any previous warnings
      setWarning();
      setLoading(true);
      const locationName = event.target.name.value;
      const image = event.target.image.files[0];

      if(!image){
        setWarning("Please select an image");
        setLoading(false);
        return; 
      }

      const imageUrl = image
        ? await uploadImageToFirebase(`locations/${locationName}`, locationName, image, () => {})
        : "";
      // // Create a payload for all location information
      const payload = {
        name: event.target.name.value,
        address: event.target.address.value,
        tax: event.target.tax.value,
        miles: {
          monthly: event.target.monthly_miles.value,
          weekly: event.target.weekly_miles.value,
        },
        phone: event.target.phone.value,
        code: event.target.code.value,
        delivery: {
          rate: event.target.delivery_rate.value,
        },
        google_maps_link: event.target.google_maps_link.value,
        google_iframe: event.target.google_iframe.value,
        apple_maps_link: event.target.apple_maps_link.value,
        image: imageUrl,
      };

      // Add the Location to the database
      const response = await addLocation(user, payload);
      // Fetch the Location again to update the list
      fetchLocations(() => {
        navigate(`/locations/info?id=${response}`);
      });
      refreshData();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="container grid">
      <Heading title="Add Location" />
      <form onSubmit={submitHandler} className="grid gap-8">
        <div className="grid text-sm md:grid-cols-4 gap-x-8 gap-y-4 text-stone-400 md:grid">
          <input className="hidden" name="id" disabled required />
          <div className="grid">
            <label className="text-sm">Name:</label>
            <input className="form-input" name="name" required />
          </div>
          <div className="grid">
            <label className="text-sm"> ADDRESS:</label>
            <input className="form-input" name="address" required />
          </div>

          <div className="grid">
            <label className="text-sm">TAX(%):</label>
            <input className="form-input" type="number" name="tax" step="0.001" max="1" required />
          </div>
          <div className="grid">
            <label className="text-sm">DELIVERY RATE:</label>
            <input
              className="form-input"
              type="number"
              name="delivery_rate"
              required
            />
          </div>
          <div className="grid">
            <label className="text-sm">WEEKLY MILES:</label>
            <input
              className="form-input"
              type="number"
              name="weekly_miles"
              required
            />
          </div>
          <div className="grid">
            <label className="text-sm">MONTHLYLY MILES:</label>
            <input
              className="form-input"
              type="number"
              name="monthly_miles"
              required
            />
          </div>
          <div className="grid">
            <label className="text-sm">CODE :</label>
            <input className="form-input" name="code" required />
          </div>
          <div className="grid">
            <label className="text-sm">Google-Maps-Link :</label>
            <input className="form-input" name="google_maps_link" required />
          </div>
          <div className="grid">
            <label className="text-sm">Google-Iframe :</label>
            <input className="form-input" name="google_iframe" required />
          </div>
          <div className="grid">
            <label className="text-sm">Apple-Maps-Link :</label>
            <input className="form-input" name="apple_maps_link" required />
          </div>

          <div className="grid">
            <label className="text-sm">PHONE:</label>
            <InputMask
              mask="+1 999 999 9999"
              className="form-input"
              value={phone}
              onChange={handlePhoneChange}
              type="tel"
              name="phone"
              required
            />
          </div>
          <br />
          <div className="grid p-4 border rounded-lg cursor-pointer md:h-40 h-fit justify-items-center border-stone-400">
            <p> IMAGE:</p>
            <input
              className="hidden"
              type="file"
              name="image"
              ref={imageRef}
              onChange={onSelectImage}
              accept={ACCEPTABLE_IMAGE_FORMATS}
            />
            {image ? (
              <img
                alt="Location Image"
                onClick={() => chooseImageHandler(imageRef)}
                className="object-fit"
                src={image}
              />
            ) : (
              <AiOutlinePlus
                className="w-10 h-10"
                onClick={() => chooseImageHandler(imageRef)}
              />
            )}
          </div>
        </div>
        <div className="flex items-center space-x-4">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <button className="w-full btn-primary md:w-fit">Submit</button>
          )}
          {warning && <p className="text-red-600">{warning}</p>}
        </div>
      </form>
    </div>
  );
}
