import { useContext } from "react";
import { UtilityContext } from "../../context/util-context";
import { timestampToString } from "../../util/timestamp-to-date";
import { toTitleCase } from "../../util/toTitleCase";

export default function PaymentCard({ payment }) {
  const { navigate } = useContext(UtilityContext);

  const borderColor = payment.last_payment_error
    ? "border-red-500"
    : payment.status === "succeeded"
    ? "border-green-500"
    : payment.status === "refunded"
    ? "border-gray-500"
    : payment.status === "delinquent"
    ? "border-red-500"
    : "border-yellow-500";

  return (
    <div
      className={`${borderColor} grid items-center gap-4 p-4 text-sm border rounded-lg cursor-pointer text-center border-stone-400 md:grid-cols-5 hover:bg-stone-800 md:justify-items-stretch justify-items-center bg-stone-900 gap-x-6`}
      onClick={(e) => navigate(`/payments/info?id=${payment.id}`, e)}
    >
      <p>
        {payment.customer.firstName} {payment.customer.lastName}
      </p>
      <p>${payment.amount}</p>
      <p>{timestampToString(payment.date_created, "MM/dd/yyyy hh:mm a")}</p>
      <p className="text-left md:col-span-2">
        {toTitleCase(payment.description)}
      </p>
    </div>
  );
}
