// External Packages
import { useState, useEffect, useContext, Fragment, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { FiCheck } from "react-icons/fi";
import { MdRefresh } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

// Internal Packages
import { AuthContext } from "../../context/auth-context";
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import { toTitleCase } from "../../util/toTitleCase";

// Internal Components
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import DetailPair from "../UI/DetailPair";
import Heading from "../UI/Heading";
import { editLocation } from "../../api/add";
import { requestAdminAPI } from "../../api/posh-api";
import LinkPair from "../UI/LinkPair";
import { uploadImageToFirebase } from "../../util/upload-image-to-firebase";

export default function LocationInfo() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { user } = useContext(AuthContext);
  const { refreshData, locations, fetchLocations } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState();
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState();
  const [warning, setWarning] = useState();

  // ========================================= EDIT PARTNER
  const editHandler = async () => {
    try {
      setLoading(true);
      // Create a payload for all vehicle information
      const locationName = document.getElementById("Name").value;
      const image = document.getElementById("Image").files[0];
      const tax = document.getElementById("Tax(%)").value;
      if (isNaN(tax) || tax > 1 || tax < 0) {
        setWarning("Please enter a valid tax value 1 or less than 1");
        setLoading(false);
        return;
      } else if (!/^\d+(\.\d{1,3})?$/.test(tax)) {
        setWarning("Please enter a value with up to three decimal places.");
        setLoading(false);
        return;
      }
      const payload = {
        locationId: location.id,
        name: locationName,
        address: document.getElementById("Address").value,
        tax,
        miles: {
          weekly: document.getElementById("Weekly-Miles").value,
          monthly: document.getElementById("Monthly-Miles").value,
        },
        delivery: {
          rate: document.getElementById("Rate").value,
        },
        phone: document.getElementById("Phone").value,
        code: document.getElementById("Code").value,
        google_maps_link: document.getElementById("Google-Maps-Link").value,
        google_iframe: document.getElementById("Google-Iframe").value,
        apple_maps_link: document.getElementById("Apple-Maps-Link").value,
      };

      if (image) {
        const imageUrl = image
          ? await uploadImageToFirebase(
              `locations/${locationName}`,
              locationName,
              image,
              () => {}
            )
          : "";
        payload.image = imageUrl;
      }

      // Upload Changes
      await editLocation(user, payload);
      fetchLocations();
      setEdit(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // =================== DELETE LOCATION ===================
  const disableLocationHandler = async () => {
    try {
      setLoading(true);
      await requestAdminAPI("disable-location", { id: location.id });
      fetchLocations(() => navigate("/locations"));
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  // =================== FETCH LOCATION DATA ===================
  const fetchLocationData = useCallback(() => {
    setLoading(true);
    const fileteredLocation = locations.find((location) => {
      return location.id === id;
    });
    setLocation(fileteredLocation);
    setLoading(false);
  }, [user, id]);

  // =================== ON SELECT IMAGE ===================
  const onSelectImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const file = URL.createObjectURL(e.target.files[0]);
    setImage(file);
  };

  //   // =================== USE_EFFECT ===================
  useEffect(() => {
    fetchLocationData();
  }, [fetchLocationData]);

  if (!id)
    return (
      <div className="w-full h-full p-8 rounded-lg bg-stone-900">
        <p className="text-stone-400">Location ID Not Provided!</p>
      </div>
    );

  return (
    <div className="container">
      <Heading title="Locations Details">
        {edit ? (
          <Fragment>
            <FiCheck
              onClick={editHandler}
              className="text-lg text-white cursor-pointer md:block hover:text-stone-400"
            />
            <AiOutlineClose
              onClick={() => setEdit(false)}
              className="text-lg text-white cursor-pointer md:block hover:text-stone-400"
            />
          </Fragment>
        ) : (
          <Fragment>
            <MdRefresh
              className="text-lg text-white cursor-pointer md:block hover:text-stone-400"
              // onClick={fetchPartners}
            />
            <FaEdit
              onClick={() => setEdit(true)}
              className="text-lg text-white cursor-pointer md:block hover:text-stone-400"
            />
          </Fragment>
        )}
      </Heading>

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="info">
          <DetailPair label="Location ID" value={location.id} />
          <DetailPair
            label="Address"
            value={toTitleCase(location.address)}
            edit={edit}
          />
          <DetailPair
            label="Name"
            value={toTitleCase(location.name)}
            edit={edit}
          />

          <DetailPair
            label="Tax(%)"
            value={toTitleCase(location.tax)}
            edit={edit}
            type="number"
          />

          <DetailPair label="Code" value={location.code} edit={edit} />

          <DetailPair
            label="Monthly-Miles"
            value={location?.miles?.monthly}
            edit={edit}
            type="number"
          />
          <DetailPair
            label="Weekly-Miles"
            value={location?.miles?.weekly}
            edit={edit}
            type="number"
          />
          <DetailPair
            label="Rate"
            value={location?.delivery?.rate}
            edit={edit}
            type="number"
          />
          <DetailPair
            label="Phone"
            type="tel"
            value={location?.phone}
            edit={edit}
          />

          <LinkPair
            label="Google-Maps-Link"
            // value={location?.google_maps_link}
            edit={edit}
            link={location?.google_maps_link}
            mapslink={true}
            type="text"
          />

          <LinkPair
            label="Google-Iframe"
            value={location?.google_iframe}
            edit={edit}
            link={location?.google_iframe}
            mapslink={true}
            type="text"
          />
          <LinkPair
            label="Apple-Maps-Link"
            value={location?.apple_maps_link}
            edit={edit}
            link={location?.apple_maps_link}
            mapslink={true}
            type="text"
          />
          <LinkPair
            label="Image"
            link={location.image}
            edit={edit}
            type="file"
            value={location?.image}
            mapslink={true}
          />

          <div onClick={disableLocationHandler} className="btn-warning">
            Disable Location
          </div>
          {warning && <p className="text-red-600">{warning}</p>}
        </div>
      )}
    </div>
  );
}
