// External Imports
import { useContext, useState, Fragment } from "react";

// Internal Imports
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import { requestAdminAPI } from "../../api/posh-api";
import LoadingSpinner from "../UI/LoadingSpinner";
import Heading from "../UI/Heading";
import DetailPair from "../UI/DetailPair";
import SelectPair from "../UI/SelectPair";

/** ========================================================================
 * * Add Coupon Page
 * Renders a page, which allows adding a new coupon to the database.
 * ========================================================================== */

export default function AddCoupon() {
  // Load Context
  const { activeCustomers, fetchCoupons } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);

  /* ======================= ADD COUPON ======================= */
  const addCouponHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const payload = {
        code: e.target["Coupon Code"].value,
        type: e.target["Type"].value.toLowerCase(),
        amount: e.target["Amount"].value,
        reusable: e.target["Reusable"].value === "Yes",
        forId:
          e.target["For"].value === "false" ? false : e.target["For"].value,
      };
      const response = await requestAdminAPI("add-coupon", payload);
      fetchCoupons(() => {
        navigate("/coupons/info?id=" + response.id);
        setLoading(false);
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <Heading
        title="Add Coupon"
        text="Coupons can be used by anyone unless a customer is specified. 
        If you do not want the coupon to be used again, uncheck reusable."
      />

      <form onSubmit={addCouponHandler} className="grid gap-4">
        <div className="info">
          <DetailPair label="Coupon Code" edit={true} />
          <SelectPair
            options={["Percentage", "Fixed"]}
            label="Type"
            edit={true}
          />
          <DetailPair label="Amount" edit={true} />
          <SelectPair options={["No", "Yes"]} label="Reusable" edit={true} />
          <SelectPair
            options={[
              { id: false, name: "Everyone" },
              ...activeCustomers.map((c) => ({
                id: c.id,
                name: `${c.firstName} ${c.lastName}`,
              })),
            ]}
            label="For"
            edit={true}
          />
        </div>
        <div className="flex items-center space-x-4">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Fragment>
              <button className="btn-primary md:w-fit">Confirm</button>
              <p className="warning">{warning}</p>
            </Fragment>
          )}
        </div>
      </form>
    </div>
  );
}
