// External Packages
import { useContext, useEffect, useState, Fragment } from "react";

// Internal Packages
import { addPayment } from "../../api/add";
import { AuthContext } from "../../context/auth-context";
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import LoadingSpinner from "../UI/LoadingSpinner";
import { checkBalance } from "../../api/fetch";
import { format } from "date-fns";
import { truncate } from "../../util/format-text";

export default function PaymentForm({
  selectedCustomer,
  bookingId,
  amount,
  description,
}) {
  const { user } = useContext(AuthContext);
  const { customers, refreshData } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState(false);
  const [type, setType] = useState("payment");

  // Select initial customer
  const [customer, setCustomer] = useState(selectedCustomer || customers[0]);

  // Select initial payment method
  let methods = [];
  if (customer.default_payment_method)
    methods.push(customer.default_payment_method);
  if (customer.payment_methods)
    methods = [...methods, ...customer.payment_methods];
  const [paymentMethod, setPaymentMethod] = useState(methods[0]);

  // Select Customer Handler
  const selectCustomer = (e) => {
    setCustomer(
      customers.filter((customer) => customer.id === e.target.value)[0]
    );
  };

  // Select Payment Method Handler
  const selectPaymentMethod = (e) => {
    setPaymentMethod(methods.filter((pm) => pm.id === e.target.value)[0]);
  };

  // =================== CHECK BALANCE HANDLER ===================
  const checkBalanceHandler = async () => {
    try {
      setWarning(false);
      setMessage(false);
      setLoading(true);
      if (!paymentMethod.fca_id)
        throw new Error("No financial connection found.");
      const balance = await checkBalance(user, {
        fca_id: paymentMethod.fca_id,
      });
      const m =
        `Balance: $${balance.current.toFixed(2)}` +
        ` on ${format(new Date(balance.as_of * 1000), "MM/dd/yyyy hh:mm a")}`;
      setMessage(m);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setWarning(err.message);
    }
  };

  // =================== SUBMIT PAYMENT HANDLER ===================
  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      setWarning(false);
      setMessage(false);
      setLoading(true);
      const payload = {
        customerId: customer.id,
        amount: parseFloat(e.target.amount.value).toFixed(2),
        type: e.target.charge.value,
        description: e.target.description.value,
      };

      if (payload.amount <= 0) {
        setLoading(false);
        return;
      }

      if (e.target.charge.value === "payment") {
        payload.payment_method_id = e.target.payment_method_id.value;
      }
      if (e.target.charge.value === "invoice") {
        payload.days_until_due = e.target.days_until_due.value;
      }
      if (e.target.booking.value !== "") {
        payload.bookingId = e.target.booking.value;
      }

      await addPayment(user, payload);

      refreshData(navigate("/payments"));
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // Automatically fill amount field if amount is passed
  useEffect(() => {
    if (amount) document.getElementsByName("amount")[0].value = amount;
  }, [amount]);

  return (
    <form onSubmit={submitHandler} className="grid gap-8">
      <div className="grid text-sm md:grid-cols-4 md:gap-x-8 md:gap-y-4 text-stone-400">
        <div className="grid">
          <label className="text-sm">CUSTOMER:</label>
          <select
            className="form-input"
            name="customer"
            defaultValue={customer?.id || customers[0].id}
            onChange={selectCustomer}
            disabled={selectedCustomer ? true : false}
          >
            {customers.map((customer) => (
              <option value={customer.id} key={customer.id}>
                {truncate(`${customer.firstName} ${customer.lastName}`, 30)}
              </option>
            ))}
          </select>
        </div>
        <div className="grid">
          <label className="text-sm">CHARGE:</label>
          <select
            onChange={(e) => setType(e.target.value)}
            className="form-input"
            name="charge"
          >
            <option value="payment">Now</option>
            <option value="invoice">Send Invoice</option>
          </select>
        </div>
        {type == "payment" && (
          <div className="grid">
            <label className="text-sm">PAYMENT METHOD:</label>
            {methods.length > 0 ? (
              <select
                onChange={(e) => selectPaymentMethod(e)}
                className="form-input"
                name="payment_method_id"
              >
                {methods.map((pm) => (
                  <option value={pm.id}>
                    {pm.brand} {pm.last4}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className="form-input-disabled"
                name="payment_method_id"
                placeholder="No Payment Methods Attached"
                disabled
              />
            )}
          </div>
        )}
        {type == "invoice" && (
          <div className="grid">
            <label className="text-sm">DAYS UNTIL DUE:</label>
            <input
              type="number"
              name="days_until_due"
              className="form-input"
              required
            />
          </div>
        )}
        <div className="grid">
          <label className="text-sm">ASSOCIATED BOOKING ID:</label>
          <input
            className="form-input"
            name="booking"
            defaultValue={bookingId || ""}
          />
        </div>
        <div className="grid">
          <label className="text-sm">AMOUNT:</label>
          <input className="form-input" name="amount" required/>
        </div>
        <div className="grid md:col-span-3">
          <label className="text-sm">DESCRIPTION:</label>
          <input
            className="form-input"
            name="description"
            defaultValue={description || ""}
            placeholder="Description"
            required
          />
        </div>
      </div>

      <div className="flex items-center space-x-4">
        {loading ? (
          <div className="p-2">
            <LoadingSpinner classes="w-6 h-6 text-yellow-600" />
          </div>
        ) : (
          <Fragment>
            {paymentMethod?.routing_number && (
              <div onClick={checkBalanceHandler} className="btn-secondary">
                Check Balance
              </div>
            )}
            <button type="submit" className="btn-primary">
              Charge Customer
            </button>
          </Fragment>
        )}
        {warning && <p className="warning">{warning}</p>}
        {message && <p className="text-stone-400">{message}</p>}
      </div>
    </form>
  );
}
