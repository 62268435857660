import React from "react";

const ConfirmationModal = ({ open, setOpen,message,onConfirm }) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="confimation-modal transition-all duration-200">
        <h2 className="text-lg font-bold text-center dark:text-stone-200">Confirm Deletion</h2>
        <p className="text-center dark:text-stone-200">
          {message}
        </p>
        <div className="flex justify-center mt-4">
          <button
            className="mr-2 text-gray-200 hover:text-gray-300"
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
          <button className="btn-warning" onClick={onConfirm}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
