import { useState, useEffect, useContext } from "react";
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import { useCallback } from "react";
import Heading from "../UI/Heading";
import DetailPair from "../UI/DetailPair";
import { timestampToDate } from "../../util/timestamp-to-date";
import { MdRefresh } from "react-icons/md";
import RevenueGraph from "./RevenueGraph";

export default function GeneralPage() {
  const { navigate } = useContext(UtilityContext);
  const {
    bookings,
    vehicles,
    payments,
    charging,
    refreshData,
    locations,
  } = useContext(DataContext);
  const [data, setData] = useState({
    activeBookings: 0,
    upcomingBookings: 0,
    completedBookings: 0,
    activeVehicles: 0,
    disabledVehicles: 0,
    customersTotal: 0,
    revenueYTD: 0,
    revenueMTD: 0,
    expenses: 0,
  });

  const calculateData = useCallback(() => {
    const temp = { ...data };

    // Calculate Booking Data
    bookings.forEach((booking) => {
      if (booking.status === "active") {
        temp.activeBookings++;
      } else if (
        booking.status === "confirmed" ||
        booking.status === "pending"
      ) {
        temp.upcomingBookings++;
      } else if (booking.status === "completed") {
        temp.completedBookings++;
      }
    });

    // Calculate Vehicle Data
    vehicles.forEach((vehicle) => {
      if (vehicle.status.available) temp.activeVehicles++;
      else temp.disabledVehicles++;
    });

    // Calculate Revenue Data
    payments.forEach((payment) => {
      if (payment.status === "succeeded") {
        if (
          timestampToDate(payment.date_created).getFullYear() ===
          new Date().getFullYear()
        ) {
          temp.revenueYTD += Number(payment.amount);
          if (
            timestampToDate(payment.date_created).getMonth() ===
            new Date().getMonth()
          ) {
            temp.revenueMTD += Number(payment.amount);
          }
        }
      }
    });

    // Calculate Revenue by Location
    payments.forEach((payment) => {
      const date = timestampToDate(payment.date_created);
      if (
        date.getFullYear() !== new Date().getFullYear() ||
        date.getMonth() !== new Date().getMonth() ||
        payment.status !== "succeeded"
      )
        return;

      let vehicleId;
      if (payment.vehicle) vehicleId = payment.vehicle.id;
      else if (payment.metadata) vehicleId = payment.metadata.vehicleId;
      else return;
      if (!vehicleId) return;
      const vehicle = vehicles.filter((vehicle) => vehicle.id === vehicleId)[0];
      const locationName = vehicle?.location
        .replace(/,\s*/g, "")
        .replace(/\s+/g, ""); // Remove spaces and commas
      const revenueKey = `revenue${locationName}`;
      // Ensure the key exists in the temp object, or initialize it if not
      if (temp[revenueKey] !== undefined) {
        temp[revenueKey] += Number(payment.amount);
      } else {
        // If the location doesn't exist yet, initialize it with the payment amount
        temp[revenueKey] = Number(payment.amount);
      }
    });

    // Calculate Expenses Data
    charging.forEach((charge) => {
      if (
        new Date(charge["ChargeStartDateTime"]).getMonth() ===
        new Date().getMonth()
      )
        temp.expenses += Number(charge["Total Inc. VAT"]);
    });

    // Format Data
    temp.revenueYTD = temp?.revenueYTD.toFixed(2);
    temp.revenueMTD = temp?.revenueMTD.toFixed(2);
    temp.expenses = temp?.expenses.toFixed(2);

    setData(temp);
  });

  useEffect(() => {
    // Creating dynamic properties for each location
    if (locations && locations.length > 0) {
      const updatedData = { ...data };

      locations.forEach((location) => {
        const locationName = location.name.replace(/\s+/g, ""); // Remove spaces from location names
        const revenueKey = `revenue${locationName}`;
        updatedData[revenueKey] = 0; // Set the default value, can be updated with actual data
      });

      setData(updatedData);
    }
    calculateData();
  }, []);

  return (
    <div className="container">
      <Heading
        title="General"
        isBackArrow={false}
        text="The following graph summarizes revenue
       for each month including all transactions for the year. The revenue is not distributed."
      >
        <MdRefresh onClick={refreshData} className="icon" />
      </Heading>

      <div className="hidden md:block">
        <RevenueGraph />
      </div>

      <hr className="hr" />

      <div className="info">
        <DetailPair label="Active Bookings" value={data.activeBookings} />
        <DetailPair label="Upcoming Bookings" value={data.upcomingBookings} />
        <DetailPair label="Completed Bookings" value={data.completedBookings} />
        <DetailPair label="Active Vehicles" value={data.activeVehicles} />
        <DetailPair label="Disabled Vehicles" value={data.disabledVehicles} />
        <DetailPair label="Total Customers" value={data.customersTotal} />
        <DetailPair label="Revenue YTD" value={"$" + data.revenueYTD} />
        <DetailPair label="Revenue MTD" value={"$" + data.revenueMTD} />
        {locations.map((location) => {
          const locationKey = location.name
            .replace(/,\s*/g, "")
            .replace(/\s+/g, ""); // Clean up location name
          const revenueKey = `revenue${locationKey}`; // Create the dynamic key for revenue
          const revenue = data[revenueKey] || 0;

          // Check if the location is disabled and its revenue is zero
          if (location.disabled && revenue === 0) {
            return null; // Skip this location
          }

          return (
            <DetailPair
              key={revenueKey}
              label={`Revenue ${location.name}`}
              value={`$${revenue.toFixed(2)}`}
            />
          );
        })}

        <DetailPair label="EV Charging" value={"$" + data.expenses} />

        <hr className="hr md:col-span-3" />

        <div
          onClick={(e) => navigate("/bookings/add", e)}
          className="btn-secondary"
        >
          Add Booking
        </div>
        <div
          onClick={(e) => navigate("/payments/add", e)}
          className="btn-secondary"
        >
          Add Payment
        </div>
        <div
          onClick={(e) => navigate("/vehicles/add", e)}
          className="btn-secondary"
        >
          Add Vehicle
        </div>
      </div>
    </div>
  );
}
