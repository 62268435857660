// External Packages
import { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

// Internal Packages
import { AuthContext } from "../../context/auth-context";
import { UtilityContext } from "../../context/util-context";
import { DataContext } from "../../context/data-context";
import { toTitleCase } from "../../util/toTitleCase";
import { timestampToString } from "../../util/timestamp-to-date";
import { fetchExpenseById } from "../../api/fetch";

// External Components
import { BiTrash } from "react-icons/bi";

// Internal Components
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import DetailPair from "../UI/DetailPair";
import Heading from "../UI/Heading";
import { deleteExpense } from "../../api/add";

export default function ExpenseInfo() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { user } = useContext(AuthContext);
  const { fetchExpenses } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  const [expense, setExpense] = useState();
  const [loading, setLoading] = useState(true);

  const fetchExpenseData = useCallback(() => {
    setLoading(true);
    fetchExpenseById(user, id)
      .then((data) => {
        setExpense(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [user, id]);

  useEffect(() => {
    fetchExpenseData();
  }, [fetchExpenseData]);

  const deleteHandler = async () => {
    try {
      setLoading(true);
      await deleteExpense(user, id);
      fetchExpenses(() => navigate("/expenses"));
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  if (!id)
    return (
      <div className="w-full h-full p-8 rounded-lg bg-stone-900">
        <p className="text-stone-400">Expense ID Not Provided!</p>
      </div>
    );

  if (!loading && !expense)
    return (
      <div className="w-full h-full p-8 rounded-lg bg-stone-900">
        <p className="text-stone-400">Expense Record Not Found!</p>
      </div>
    );

  return (
    <div className="container">
      <Heading title="Expense Details">
        <BiTrash
          onClick={() => deleteHandler()}
          className="text-lg text-white cursor-pointer md:block hover:text-stone-400"
        />
      </Heading>

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="info">
          <DetailPair label="Expense ID" value={expense.id} />
          <DetailPair
            label="Date Incurred"
            value={timestampToString(
              expense.date,
              "eee dd MMM, yyyy - hh:mm a"
            )}
          />
          <DetailPair label="Amount" value={"$" + expense.amount.toString()} />
          <DetailPair
            label="Vehicle ID"
            value={expense.vehicle.id}
            link={`/vehicles/info?id=${expense.vehicle.id}`}
          />
          <DetailPair
            label="Vehicle"
            value={`${expense.vehicle.make} ${expense.vehicle.model} ${expense.vehicle.year}`}
            link={`/vehicles/info?id=${expense.vehicle.id}`}
          />
          <DetailPair
            label="Booking ID"
            value={expense.bookingId || "-"}
            link={expense.bookingId && `/bookings/info?id=${expense.bookingId}`}
          />
          <div>
            <p className="text-stone-400">Attachment:</p>
            {expense.attachment ? (
              <a
                href={expense.attachment}
                className="text-yellow-600 underline"
                target="_blank"
              >
                open
              </a>
            ) : (
              <p className="text-stone-200">-</p>
            )}
          </div>
          <DetailPair label="Type" value={expense?.type || "-"} />
          <div className="md:col-span-2">
            <DetailPair
              label="Description"
              value={toTitleCase(expense.description)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
