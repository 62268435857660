// External Imports
import Papa from "papaparse";
import { useContext, useState } from "react";

// Internal Imports
import { requestAdminAPI } from "../../api/posh-api";
import { DataContext } from "../../context/data-context";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";

// Internal Components
import { UtilityContext } from "../../context/util-context";
import Heading from "../UI/Heading";

/** ========================================================================
 * * Add Charging Page
 * Renders a page, which allows the admin to upload EV charging records.
 * ========================================================================== */

export default function AddCharging() {
  // Load Context
  const { fetchCharging } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [ignored, setIgnored] = useState([]);

  /* ======================= SUBMIT CSV FILE ======================= */
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const file = e.target.file.files[0];

      // Check if the file's MIME type is CSV (additional check)
      if (file.type !== "text/csv") {
        setWarning("Invalid file type! Please upload a valid CSV file.");
        setLoading(false);
        return;
      }

      // Read the file content
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        const fileContent = target.result;

        // Try to parse the CSV file using PapaParse
        Papa.parse(fileContent, {
          header: true,
          skipEmptyLines: true,
          complete: async function (results) {
            // Check if there's valid data
            if (results.errors.length > 0 || results.data.length === 0) {
              setWarning(
                "Invalid CSV file! Please upload a properly formatted CSV file."
              );
              setLoading(false);
              return;
            }

            // Process the valid CSV data
            const parsedData = results.data;
            await uploadChargingHandler(parsedData.reverse());
            setLoading(false);
          },

          error: function () {
            // Handle parsing error
            setWarning("Failed to parse CSV. Please check the file format.");
            setLoading(false);
            return;
          },
        });
      };

      // Read the file as text
      reader.readAsText(file);
    } catch (err) {
      console.error(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  /* ======================= UPLOAD CHARGING ======================= */
  const uploadChargingHandler = async (data) => {
    try {
      // Split Data into chunks of 100
      const chunks = [];
      for (let i = 0; i < data.length; i += 100) {
        chunks.push(data.slice(i, i + 100));
      }

      // Create array for ignored entries
      const ignored = [];

      // Upload each chunk
      await Promise.all(
        chunks.map(async (chunk) => {
          const res = await requestAdminAPI("upload-charging", {
            charging: chunk,
          });
          ignored.push(...res);
        })
      );

      fetchCharging();
      setIgnored(ignored);
      navigate("charging");
      setLoading(false);
    } catch (err) {
      console.error(err);
      setWarning(err.message);
    }
  };

  if (loading)
    return (
      <div className="w-full h-full p-8 overflow-scroll rounded-xl bg-stone-900">
        <LoadingSpinnerWrapper />
      </div>
    );

  return (
    <div className="container">
      <div className="grid gap-8">
        <div className="grid gap-2">
          <Heading title="Add EV Charging" />
          <hr className="hr" />
          <p className="text-sm text-stone-400">
            Download the csv file from the Tesla App (Profile &#62; Account
            &#62; Charging &#62; History) and upload the file here. Older
            records and duplicates are automatically ignored.
          </p>
        </div>

        <form onSubmit={submitHandler} className="grid gap-6">
          <div className="grid gap-4">
            <label className="form-label">Upload CSV:</label>
            <input
              type="file"
              name="file"
              className="form-input"
              required
              accept=".csv"
            />
          </div>
          <button type="submit" className="btn-primary md:w-32">
            Submit
          </button>
        </form>

        {ignored.length > 0 && <p>Entries Ignored: {ignored.length}</p>}
        {warning && <p className="warning">{warning}</p>}
      </div>
    </div>
  );
}
