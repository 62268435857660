// External Imports
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { DataContext } from "../../context/data-context";
import { MdRefresh } from "react-icons/md";

// Internal Imports
import { arrayToCsv } from "../../util/arrayToCsv";
import { requestAdminAPI } from "../../api/posh-api";
import Heading from "../UI/Heading";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import BarGraph from "../UI/BarGraph";
import BookingCard from "../bookings-page/BookingCard";
import { downloadBookingsCSV } from "../../util/downloadBookingsCsv";

/** ========================================================================
 ** Vehicle Report Page
 * This page displays vehicle's booking history and revenue report.
 * ========================================================================== */
export default function VehicleReport() {
  const { vehicles } = useContext(DataContext);

  // Get Vehicle ID from URL
  const [searchParms] = useSearchParams();
  const id = searchParms.get("id");
  const vehicle = vehicles.find((vehicle) => vehicle.id === id);

  const [loadingRevenue, setLoadingRevenue] = useState(false);
  const [loadingBookings, setLoadingBookings] = useState(false);
  const [warning, setWarning] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [revenue, setRevenue] = useState([]);
  const [bookings, setBookings] = useState([]);

  // =================== FETCH VEHICLE REVENUE ===================
  const fetchVehicleRevenue = async () => {
    try {
      setLoadingRevenue(true);
      const response = await requestAdminAPI("fetch-vehicle-revenue", {
        vehicles: [id],
        year: year,
      });
      setRevenue(response);
      setLoadingRevenue(false);
    } catch (err) {
      setWarning(err.message);
      setLoadingRevenue(false);
    }
  };

  // =================== FETCH VEHICLE BOOKINGS ===================
  const fetchVehicleBookings = async () => {
    try {
      setLoadingBookings(true);
      const response = await requestAdminAPI("fetch-vehicle-bookings", {
        vehicleId: id,
        year: year,
      });
      setBookings(response);
      setLoadingBookings(false);
    } catch (err) {
      setWarning(err.message);
      setLoadingBookings(false);
    }
  };

  // =================== DOWNLOAD CSV ===================
  const downloadCSV = (data) => {
    if (data === "revenue") arrayToCsv(revenue, `${id}-${year}-revenue`);
    else downloadBookingsCSV(bookings, vehicles);
  };

  useEffect(() => {
    if (id) {
      fetchVehicleRevenue();
      fetchVehicleBookings();
    } else setWarning("Vehicle ID not provided.");
  }, [id, year]);

  return (
    <div className="container">
      <Heading
        title="Vehicle Report"
        isBackArrow={true}
        text="The following graph shows revenue generated by the specific vehicle. 
        This does not include deposits and other reimbursements."
      >
        <select
          className="text-sm cursor-pointer input"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        >
          {Array.from(
            { length: new Date().getFullYear() - 2022 + 1 },
            (_, i) => {
              return <option value={2022 + i}>{2022 + i}</option>;
            }
          )}
        </select>
        <MdRefresh onClick={fetchVehicleRevenue} className="text-lg icon" />
        <FiDownload
          onClick={() => downloadCSV("revenue")}
          className="text-lg icon"
        />
      </Heading>
      <p className="text-sm">
        {vehicle?.year} {vehicle?.make} {vehicle?.model} -{" "}
        {vehicle?.vin.substring(13, 17)}
      </p>
      {loadingRevenue ? (
        <div className="min-h-[352px]">
          <LoadingSpinnerWrapper />
        </div>
      ) : (
        <div>
          <BarGraph data={revenue} expenses={true} />
        </div>
      )}

      {warning && <div className="warning">{warning}</div>}

      <Heading
        title="Vehicle Bookings"
        isBackArrow={false}
        text="This includes all bookings spanning the selected year."
      >
        <MdRefresh onClick={fetchVehicleBookings} className="text-lg icon" />
        <FiDownload
          onClick={() => downloadCSV("bookings")}
          className="text-lg icon"
        />
      </Heading>
      <p className="text-sm">
        {vehicle?.year} {vehicle?.make} {vehicle?.model} -{" "}
        {vehicle?.vin.substring(13, 17)}
      </p>

      {loadingBookings ? (
        <div className="min-h-[390px]">
          <LoadingSpinnerWrapper />
        </div>
      ) : (
        <div className="list h-[400px]">
          {bookings.map((booking) => (
            <BookingCard booking={booking} key={booking.id} />
          ))}
        </div>
      )}
    </div>
  );
}
