// External Imports
import { useContext } from "react";

// Internal Imports
import { UtilityContext } from "../../context/util-context";

/** ========================================================================
 * * Location Card Component
 * Renders a card displaying Location information.
 * ========================================================================== */

export default function LocationCard({ location }) {
  const { navigate } = useContext(UtilityContext);

  return (
    <div
      onClick={(e) => navigate(`/locations/info?id=${location.id}`, e)}
      className="grid items-center gap-4 p-4 text-sm border rounded-lg cursor-pointer md:grid-cols-4 hover:bg-stone-800 justify-items-center border-stone-400 bg-stone-900 gap-x-6"
    >
      <div className="flex items-center space-x-4">
        <p className="font-semibold text-stone-200">{location.name}</p>
      </div>
      <div className="text-center">
        <p className="text-stone-200">{location.address || "-"}</p>
      </div>
      <div className="text-center">
        <p className="text-stone-200">
          {location.tax ? `${location.tax}` : "-"}
        </p>
      </div>
      <div className="hidden text-center md:block">
        <p className="text-stone-200">
          {location.code ? `${location.code}` : "-"}
        </p>
      </div>
    </div>
  );
}
