import { ACCEPTABLE_IMAGE_FORMATS } from "../../util/constants";

export default function LinkPair({ 
  label,
  link,
  edit,
  id,
  type,
  value,
  required,
  mapslink = false,
  onChange
}) {
  return (
    <div className="cursor-pointer">
      <p className="text-stone-400">{label}:</p>

      {!edit &&
        (link ? (
          <a href={link} target="_blank" rel="noopener noreferrer" className="text-yellow-600 underline">
            {!mapslink && value || "Link"}
          </a>
        ) : (
          <p className="text-stone-200">-</p>
        ))}
      {edit && (
        <input
          className="w-full form-input"
          type={type || "file"}
          id={id || label}
          required={required}
          accept={type === "file" ? ACCEPTABLE_IMAGE_FORMATS : undefined}
          {...(type !== "file" && { defaultValue: mapslink ? link : undefined })}
          onChange={onChange}
        />
      )}
    </div>
  );
}
