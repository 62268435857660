/** ======================= FETCH ALL DATA ======================= */
export const fetchAllData = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-all-data`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= EXCHANGE SMARTCAR CODE ======================= */
export const exchangeCode = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/exchange-code`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH ALL BOOKINGS ======================= */
export const fetchAllBookings = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-all-bookings`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH ALL CUSTOMERS ======================= */
export const fetchAllCustomers = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-all-customers`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH ALL CUSTOMERS ======================= */
export const fetchAllPartners = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-all-partners`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH ALL VEHICLES ======================= */
export const fetchAllVehicles = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-all-vehicles`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH ALL PAYMENTS ======================= */
export const fetchAllPayments = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-all-payments`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH ALL COUPONS ======================= */
export const fetchAllCoupons = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-all-coupons`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH COUPON ======================= */
export const fetchCoupon = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-coupon`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH ALL INVOICES ======================= */
export const fetchAllInvoices = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-all-invoices`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH ALL CHARGING ======================= */
export const fetchAllCharging = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-all-charging`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH ALL EXPENSES ======================= */
export const fetchAllExpenses = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-all-expenses`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH BOOKING BY ID ======================= */
export const fetchBookingById = async (user, bookingId) => {
  if (!user || !bookingId) return;
  const idToken = await user.getIdToken();
  const payload = { bookingId: bookingId };

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-booking`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH CUSTOMER BY ID ======================= */
export const fetchCustomerById = async (user, customerId) => {
  if (!user || !customerId) return;
  const idToken = await user.getIdToken();
  const payload = { customerId: customerId };

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-customer`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH PARTNER BY ID ======================= */
export const fetchPartnerById = async (user, partnerId) => {
  if (!user || !partnerId) return;
  const idToken = await user.getIdToken();
  const payload = { partnerId: partnerId };

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-partner`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH VEHICLE BY ID ======================= */
export const fetchVehicleById = async (user, vehicleId) => {
  if (!user || !vehicleId) return;
  const idToken = await user.getIdToken();
  const payload = { id: vehicleId };

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-vehicle`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH PAYMENT BY ID ======================= */
export const fetchPaymentById = async (user, paymentId) => {
  if (!user || !paymentId) return;
  const idToken = await user.getIdToken();
  const payload = { paymentId: paymentId };

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-payment`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH Charging BY Booking ID ======================= */
export const fetchChargingByBookingId = async (user, bookingId) => {
  if (!user || !bookingId) return;
  const idToken = await user.getIdToken();
  const payload = { bookingId: bookingId };

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-booking-charging`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH TOLLS ======================= */
export const fetchTolls = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-tolls`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH LEADS ======================= */
export const fetchLeads = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-leads`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= EDIT LEAD ======================= */
export const editLead = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/edit-lead`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH TOLLS ======================= */
export const fetchTollsByBookingId = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-booking-tolls`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH EXPENSE BY ID ======================= */
export const fetchExpenseById = async (user, expenseId) => {
  if (!user || !expenseId) return;
  const idToken = await user.getIdToken();
  const payload = { expenseId: expenseId };

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-expense`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH SUBSCRIPTION PLANS ======================= */
export const fetchSubscriptionPlans = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/public/fetch-subscription-plans`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_APP_AUTH_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH SMARTCAR AUTH ======================= */
export const fetchSmartcarAuth = async (user) => {
  if (!user) return;
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/smartcar-auth`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= SEND CONTROL SIGNAL ======================= */
export const sendControlSignal = async (user, payload) => {
  if (!user) return;
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/control-vehicle`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FORCE AGREEMENT ======================= */
export const forceAgreement = async (user, bookingId) => {
  if (!user || !bookingId) return;
  const idToken = await user.getIdToken();
  const payload = { bookingId: bookingId };

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/force-agreement`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= DELETE INVOICE ======================= */
export const deleteInvoice = async (user, payload) => {
  if (!user || !payload) throw new Error("Missing required parameters");
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/delete-invoice`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ACTIVATE VEHICLE ======================= */
export const activateVehicle = async (user, payload) => {
  if (!user || !payload) throw new Error("Missing required parameters");
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/activate-vehicle`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ACTIVATE VEHICLE ======================= */
export const deactivateVehicle = async (user, payload) => {
  if (!user || !payload) throw new Error("Missing required parameters");
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/deactivate-vehicle`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= GET VEHICLE STATS ======================= */
export const getVehicleStats = async (user, payload) => {
  if (!user || !payload) throw new Error("Missing required parameters");
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-vehicle-revenue`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** /** ======================= FETCH VEHICLE REPORT ======================= */
export const fetchVehicleReport = async (user, payload) => {
  if (!user || !payload) throw new Error("Missing required parameters");
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-vehicle-report`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** /** ======================= FETCH VEHICLE REPORT ======================= */
export const fetchVehicleLocations = async (user, payload) => {
  if (!user) throw new Error("Missing required parameters");
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-locations`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** /** ======================= FETCH ALL BRANDS ======================= */
export const fetchAllBrands = async (user, payload) => {
  if (!user) throw new Error("Missing required parameters");
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-brands`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** /** ======================= DECODE VIN ======================= */
export const decodeVin = async (user, payload) => {
  if (!user) throw new Error("Missing required parameters");
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/decode-vin`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= CHECK BALANCE ======================= */
export const checkBalance = async (user, payload) => {
  if (!user) throw new Error("Missing required parameters");
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/check-balance`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= CHECK ACH STATUS ======================= */
export const checkAchStatus = async (user, payload) => {
  if (!user) throw new Error("Missing required parameters");
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/check-ach-status`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH ALL LOCATIONS ======================= */
export const fetchAllLocations = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-all-locations`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH ALL Dues ======================= */
export const fetchAllDues = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/fetch-all-dues`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};
